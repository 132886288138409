<template>
  <div>
    <Divider dashed style="color:#fff">配置巡检计费</Divider>
    <div>
      <div class="m-b-10">
        <span class="text-red">*</span>计费
      </div>
      <RadioGroup
        class="m-b-10"
        v-model="isCharge"
      >
        <Radio
          :label="0"
          border
        >
          <span>否</span>
        </Radio>
        <Radio
          :label="1"
          border
        >
          <span>是</span>
        </Radio>
      </RadioGroup>
      <div class="text-right">
        <Button
          type="primary"
          @click="handleSubmit"
        >提交</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { calculateActionCost } from '@/api/msp/taskitem'
export default {
  data () {
    return {
      isCharge: 0
    }
  },
  computed: {
    selectedTaskItemIds: {
      get () {
        return this.$store.state.workOrder.selectedTaskItemIds
      },
      set (val) {
        this.$store.commit('set_selected_task_item_ids', val)
      }
    }
  },
  methods: {
    handleSubmit () {
      calculateActionCost({ isCharge: Boolean(this.isCharge), taskitemId: this.selectedTaskItemIds[0] }).then(res => {
        if (res && res.errcode === 0) {
          this.$store.dispatch('getTaskItemData')
          this.$Notice.success({ desc: '操作成功' })
        }
      })
    }
  }
}
</script>
